
.modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(169, 169, 169, 0.804);
    /* Light grey color with 50% transparency */
    z-index: 999;
    position: fixed;
}


.pro-container2 {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
}
@media (max-width: 800px) {
    .modal-container {
        padding: 0;
        width: 50vh;
        height: 80vh;
    }
    
}
.create-post {
    margin-top: 20px;
}

.contain {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Input styles */
.input-group {
    margin-bottom: 15px;
}

.input-field {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* File input styles */
.input-field[type="file"] {
    padding: 10px 0;
}

/* Textarea styles */
.textarea-field {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

/* Button styles */
.upload-button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button:hover {
    background-color: #45a049;
}
.close{
    float: right;
    margin: 3px;
    font-size: 25px;
    cursor: pointer;
}