
.main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.section {
    width: 75%;
    padding: 20px;
    text-align: left;
    background-color: rgb(237, 250, 237);
    color: black;
    border-radius: 5px;
    margin: 20px;

}
li{
    margin-left: 20px;
}
.canola-contract-farming{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.canola-contract-farming ol,
.canola-contract-farming ul
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media only screen and (max-width: 800px) {
    .section {
        width: 100%;
        
    }
    .main-container{
        padding-top: 120px;
    }
}