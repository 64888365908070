@import url('https://fonts.googleapis.com/css2?family=Arizonia&amp;display=swap');

/* Root */
:root {
  ---lightbrown: #44ff00;
  ---darkbrown: #38c42b;
}

/* Root */

/* Body */
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  text-decoration: none;

  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
}

body {
  overflow-x: hidden;
}

section {
  padding: 20px 150px;
  margin-right: -20px;
}

/*body*/
/*header*/
.header {
  background-color: transparent;
  position: fixed;
  z-index: 999;
  width: 100%;
  text-transform: uppercase;
  padding-left: 10px;
}

.account {
  position: fixed;
  z-index: 999;
  top: 100px;
  margin-left: 30px;
  color: blue;
  text-transform: none;
  font-size: 10px;
}

.account h3 span {
  padding-left: 10px;
}

.header .container {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .container nav {
  /* margin-left: 30px; */
  display: flex;
  align-items: center;
}

.header .container .navbar a {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  padding: 15px 20px;
}

.logo img {
  width: 60px;
}

.header .container nav .right-data {
  display: flex;
  align-items: center;
}

.header .container nav .right-data #menu {
  color: var(---black);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.123);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  font-size: 20px;
  margin-right: 20px;
  display: none;
}

.right-data .button {
  background-color: #d7d1d1;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0px 5px 10px #fff;
  color: inherit;
}

.header .container nav .right-data .button span,
.navbar a {
  font-size: 16px;
  font-weight: 800;
  color: black;
}

.header .container nav .right-data .button span:hover {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header .container .navbar a:hover {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header .container nav .right-data #menu:hover {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header .container .navbar.active {
  visibility: inherit;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  width: 200px;
  right: 0;
  padding: 10px;
  padding-bottom: 20px;
  min-height: fit-content;
  border-radius: 5px;
  position: fixed;
  z-index: 999;
  top: 120px;
  background-color: green;
  color: #fff;
}

.mobile-menu .link {
  margin-top: 30px;
  border: 2px #fff;
}

/*header*/
.main-container {
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: rgb(237, 250, 237);
  text-align: center;
}

.main-container .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-bottom: 20px;

}

.pro-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
  /* Adjust as needed */
}

.card {
  width: 300px;
  position: relative;
  background: rgb(255, 255, 255);
  padding: 20px;
}

.card::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 50%;
  height: 10px;
  bottom: 15px;
  right: 0;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  transform: rotate(5deg);
  transition: all 0.1s ease-in;
}

.card::before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 50%;
  height: 10px;
  bottom: 15px;
  left: 0;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  transform: rotate(-5deg);
  transition: all 0.1s ease-in;
}

.card:hover:before,
.card:hover:after {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
}

.card:hover:before {
  transform: rotate(-8deg);
}

.card:hover:after {
  transform: rotate(8deg);
}

.card__img {
  position: relative;
  background: #007c0d;
  background: linear-gradient(315deg, #68ffc0, #007c0d);
  width: 100%;
  height: 200px;
}

.card__img img {
  height: 200px;
}

.card__span {
  cursor: pointer;
  font-size: 11px;
  position: absolute;
  background-color: white;
  top: 10px;
  left: 10px;
  padding: 3px 7px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s ease-in;
  user-select: none;
}

.card__span:hover {
  transform: translateX(5px);
}

.card-int {
  padding: 20px 0 0 0;
}

.card-int__title {
  font-weight: bold;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
}

.card-int__button {
  cursor: pointer;
  margin: 20px 0 0 0;
  padding: 7px 20px;
  width: 100%;
  background-color: rgb(216, 255, 234);
  border: none;
  color: black;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.1s ease-in;
  user-select: none;
}

.card-int__button:active {
  box-shadow: 0px 0px 15px rgba(0, 119, 255, 0.5);
}

.card-int__button:hover::before {
  animation: effect_two 0.4s 1;
}

.card-int__button::before {
  content: 'More for this item';
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #007c0d;
  background: linear-gradient(315deg, #68ffc0, #007c0d);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateX(-99%);
  z-index: 1;
  animation: effect_one 10s infinite;
}

.card-int__button:hover::before {
  transform: translateX(0%);
}

.excerpt {
  font-size: 14px;
}

@keyframes effect_one {
  0% {
    transform: translateX(-99%);
  }

  25% {
    transform: translateX(-90%);
  }

  50% {
    transform: translateX(-80%);
  }

  75% {
    transform: translateX(-95%);
  }

  100% {
    transform: translateX(-99%);
  }
}

@keyframes effect_two {
  to {
    transform: translateX(-1%);
  }

  from {
    transform: translateX(-99%);
  }
}

.hero {
  margin: -10px;
  background-image: url("../public/img/farm.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* Ensures the background image covers the entire element */
  background-position: center;
  /* Centers the background image */
  padding-top: 50px;
  height: 350px;
  width: 100%;
}


.hero .hero-image .text h5 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-left: 100px;
}

.hero .hero-image .text p {
  font-size: 40px;
  color: black;
  font-weight: 700;
}

.hero .hero-image .text button {
  width: 300px;
  height: 70px;
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 1px solid var(---stoke);
  box-shadow: 0px 10px 20px #b7daaf;
  margin-top: 30px;
  color: black;

}

.hero .hero-image .text button a {
  font-size: 18px;
  font-weight: 700;
  color: var(---black);
}

/*service*/

.service .heading {
  text-align: center;
}

.service .heading h1 {
  font-size: 80px;
  color: var(---white);
  /* font-family: 'Arizonia', cursive; */
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}

.service .heading p {
  font-size: 20px;
  font-weight: 500;
  color: var(---black);
  line-height: 30px;
  margin-top: 20px;
}

.service .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background-color: var(---white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.158);
  border-radius: 20px;
  margin-top: 50px;
}

.service .container .s1 {
  border-radius: 30px;
  width: 380px;
  height: 300px;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
}

.service .container .s1 a {
  text-decoration: none;
  color: inherit;
}

.service .container .s1:hover {
  background-color: #d3d3d3;
  border: 2px solid var(---white);
  box-shadow: 0px 0px 20px #ce8a8a63;
}

.service .container .s1 {
  text-align: center;

}

.service .container .s1 .data .serivce-image img {
  border: 5px solid var(---white);
  border-radius: 150px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
}

.service .container .s1 .data .text {
  margin-top: 20px;
}

.service .container .s1 .data .text h1 {
  font-size: 40px;
  color: var(---white);
  /* font-family: 'Arizonia', cursive; */
  text-transform: capitalize;
  letter-spacing: 2px;
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service .container .s1 .data .text p {
  font-size: 16px;
  font-weight: 500;
  color: var(---black);
  line-height: 30px;
  margin: 20px 0px;
  text-transform: none;
}

.service .container .s1 .data .text .button a {
  font-size: 20px;
  color: var(---black);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service .container .s1 .data .text .button i {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service .container .s1 .data .text .button i:hover {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service .container .s1 .data .text .button a:hover {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*service*/
@media only screen and (max-width: 800px) {

  .s1 {
    width: 100%;
    max-width: none;
  }

  .second-nav {
    margin-top: -20px;
  }

}

/*footer*/
.footer {
  text-transform: capitalize !important;
  background-color: green;
  margin-top: 100px;
}

.footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(---black); */
}

.footer .container .logo img {
  width: 100px;
  height: auto;
}

.footer .container .navbar a {
  font-size: 16px;
  color: var(---white);
  font-weight: 600;
  padding: 0px 20px;
}

.footer .container .search h1 {
  font-size: 30px;
  color: var(---white);
}

.footer .container .search .input {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 20px;
}

.footer .container .search .input input {
  width: 100%;
  height: 50px;
  border-radius: 5px 0px 0px 5px;
  padding-left: 10px;
  font-size: 12px;
  color: var(---black);
  font-weight: 600;
}

.footer .container .search .input input::placeholder {
  font-size: 12px;
  color: var(---black);
  font-weight: 600;
}

.footer .container .search .input i {
  background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
  width: 50px;
  height: 50px;
  color: var(---white);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

/*footer*/


/* media quries */

@media (max-width:1536px) {
  section {

    padding: 10px 70px;
  }

}

@media (max-width:1280px) {
  section {
    padding: 10px 50px;
  }

  .header .container .navbar {
    position: absolute;
    background-color: var(---white);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
    width: 100%;
    top: 10%;
    right: 0%;
    z-index: 1000;
    border-radius: 30px;
    visibility: hidden;
  }

  .header .container nav .navbar a {
    display: block;
    text-align: center;
  }

  .header .container nav .right-data #menu {
    display: inherit;
  }

  .navbar {
    margin-top: 100px;
  }

  .footer .container {
    display: block;
  }

  .footer .container .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer .container .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 0px;
  }

  .footer .container .navbar nav a {
    display: block;
    line-height: 50px;
  }

  .footer .container .search {
    text-align: center;
  }

  .footer .container .search .input {
    margin: 10px auto;
  }
}

@media (min-width:1281px) {
  .mobile-menu {
    display: none;
  }
}

@media (max-width:800px) {
  .account {
    top: 60px;
    margin-left: 0;
  }

  #id {
    font-size: medium;
  }

  .navbar {
    margin-top: 100px;

  }

  .header {
    width: 100% !important;
    font-size: medium;

  }

  .mobile-menu {
    margin-top: -60px;
  }

  .logo img {
    width: 50px !important;
  }

  section {
    padding: 10px 16px;
    margin: 0;

  }

  .pro-container {
    margin: 0;
  }

  .main-container {
    padding-top: 50px;
  }

  .hero {
    margin-top: -40px;
    width: 100% !important;
  }

  .hero .hero-image .text p {
    padding: 20px;
    font-size: 25px;
  }

  .hero .hero-image .text h1 {
    font-size: 20px;
    padding: 10px;
  }

  .service .container .s1 {

    width: 330px;
  }

  .card {
    width: 100%;
  }

  .select-for-bg {
    display: none;
  }

  .footer .container .logo img {
    max-width: 100px;
  }
}

@media (max-width:640px) {
  section {
    padding: 10px 10px;
  }

  .header .container nav .right-data #menu {
    margin-right: 0px;
  }

  .header .container nav .right-data button {
    display: none;
  }
}

@media (max-width:500px) {
  .header .container .logo {
    width: 100%;
  }

  .header .container .logo img {
    width: 100px;
  }

  .header .container nav .right-data #menu {
    width: 60px;
    height: 60px;
    font-size: 16px;
  }

  .header .container .navbar {
    top: 9%;
  }

  .footer {
    margin-top: 50px;
  }

  .footer .container .search .input {
    width: 100%;
  }

  .footer .container .logo {
    width: 80%;
    margin: 10px auto;
  }

  .footer .container .logo img {
    width: 100%;
  }
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(169, 169, 169, 0.804); */
  background: #007c0d;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Button styles */
.upload-button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #45a049;
}

/* styles.css */

.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.heading {
  margin-bottom: 20px;
}

.heading h1 {
  font-size: 24px;
  font-weight: bold;
}

.form {
  width: 400px;
  padding: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #38c42b;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #38c42b;
}

.btn:focus {
  outline: none;
}